// Assuming identityId and fileKey are passed as parameters to make the function more flexible
export const generateShareableLink = async (identityId, fileKey) => {
    const apiUrl = window.location.hostname === 'localhost'
    ? 'https://8p2fv28a6h.execute-api.us-east-1.amazonaws.com/test' // Replace with your local URL
    : 'https://rsglt524nj.execute-api.us-east-1.amazonaws.com/dev'; //Changed to https://r9jefdgol1.execute-api.us-east-1.amazonaws.com/local from https://rsglt524nj.execute-api.us-east-1.amazonaws.com/dev for Testing
    
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ identityId, fileKey }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const responseBody = JSON.parse(data.body);
        // Depending on your use case, you might want to return the URL instead of logging it
        return responseBody.shareableUrl;
    } catch (error) {
        console.error('Error generating shareable URL:', error);
        // You might want to handle the error differently, e.g., by returning null or showing a message to the user
        return null;
    }
};
