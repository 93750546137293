export const toolPageConfig = {
  // Mindmap combinations
  "pdf-to-mindmap": {
    content: "Convert PDF to Mindmap",
    userInput: "PDFs",
    userOutput: "Mindmaps",
    CTAText:"Create a Mindmap",
    demos: ["/tools-demo/pdf-to-mindmap.gif"],
  },
  "notes-to-mindmap": {
    content: "Convert Notes to Mindmap",
    userInput: "Notes",
    userOutput: "Mindmaps",
    CTAText:"Create a Mindmap",
    demos: ["/tools-demo/notes-to-mindmap.gif"],
  },
  "ai-magic-to-mindmap": {
    content: "Convert AI Magic to Mindmap",
    userInput: "AI Magic",
    userOutput: "Mindmaps",
    CTAText:"Create a Mindmap",
    demos: ["/tools-demo/ai-to-mindmap.gif"],
  },

  // Gantt Chart combinations
  "pdf-to-gantt": {
    content: "Convert PDF to Gantt Chart",
    userInput: "PDFs",
    userOutput: "Gantt Chart",
    CTAText:"Create a Gantt Chart",
    demos: ["/tools-demo/pdf-to-gantt.gif"],
  },
  "notes-to-gantt": {
    content: "Convert Notes to Gantt Chart",
    userInput: "Notes",
    userOutput: "Gantt Chart",
    CTAText:"Create a Gantt Chart",
    demos: ["/tools-demo/notes-to-gantt.gif"],
  },

  // Flowchart combinations
  "pdf-to-flowchart": {
    content: "Convert PDF to Flowchart",
    userInput: "PDFs",
    userOutput: "Flowchart",
    CTAText:"Create a Flowchart",
    demos: ["/tools-demo/pdf-to-flowchart.gif"],
  },
  "notes-to-flowchart": {
    content: "Convert Notes to Flowchart",
    userInput: "Notes",
    userOutput: "Flowchart",
    CTAText:"Create a Flowchart",
    demos: ["/tools-demo/notes-to-flowchart.gif"],
  },
  "ai-magic-to-flowchart": {
    content: "Convert AI Magic to Flowchart",
    userInput: "AI Magic",
    userOutput: "Flowchart",
    CTAText:"Create a Flowchart",
    demos: ["/tools-demo/ai-to-flowchart.gif"],
  },
};
