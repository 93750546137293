import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { FaCaretDown } from 'react-icons/fa';
import NavBar from '../ui-components/LandingPage/Navbar';
import Footer from '../ui-components/LandingPage/Footer';
import './FAQ.css';
import WallOfLove from '../ui-components/LandingPage/WallOfLove';
import { useState } from 'react';
import { styled } from '@mui/system';
import CTA from '../ui-components/CTA';

const faqs = [
  {
    question: 'What is your refund policy?',
    answer: (
      <span>
        Our refund policy is designed to be fair and transparent. In general, payments are non-refundable, but if you encounter an issue due to a bug or error in our service, you may be eligible for a refund. You can learn more about{' '}
        <a href="https://map-this.com/refund-policy" target="_blank" rel="noopener noreferrer">
        our refund policy
        </a>.
      </span>
    ),
  },
  {
    question: 'How do credits work?',
    answer: (
      <span>
        Credits allow you to perform conversions (e.g., turning PDFs into mind maps). Each conversion uses a certain number of credits depending on the size and complexity of the PDF. If you need more credits, you can purchase them or upgrade to a plan that includes additional credits. Check out the detailed explanation of{' '}
        <a href="https://map-this.com/how-credits-work" target="_blank" rel="noopener noreferrer">
          how credits work
        </a>.
      </span>
    ),
  },
  {
    question: 'How do I convert a PDF into a mind map or a flowchart?',
    answer: (
      <span>
        Upload your PDF. Tell our AI what part of the PDF you want to turn into a Mind Map/Flow chart.
        Wait a few seconds, and AI will turn the PDF into a mind map!
      </span>
    ),
  },
  {
    question: 'How do I generate the best Mind Maps?',
    answer: (
      <span>
        When you upload your PDF, just mention the topic for your Mind Map. This will create mind maps on that specific topic from the PDF.
      </span>
    ),
  },
  {
    question: 'Can I edit or customize my mind map after creation?',
    answer: (
      <span>
        Absolutely! You can add new nodes or edit or delete existing mind map nodes using the Edit feature. Note: The edit feature is available on all Pro plans.
      </span>
    ),
  },
  {
    question: 'What should I do if I encounter an error during conversion?',
    answer: (
      <span>
        Getting an error during conversion is rare. If you get an error, make sure the PDF is machine readable and just try it again. The credits are not used if there is an error. If the error persists, please reach out to our support team, and they will help.
      </span>
    ),
  },
  {
    question: 'How can I share my mind map with others?',
    answer: (
      <span>
        Once you generate your mind map, you can download the mind map as an image or share it with anyone using a shareable link.
      </span>
    ),
  },
  {
    question: 'Are there limits to the size of the PDF I can upload?',
    answer: (
      <span>
        There’s a 5MB file size limit on the free plan and no size limit on the paid plan.
      </span>
    ),
  },
];

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.Mui-expanded': {
    '&::before': {
      opacity: 1,
    },
  },
  '&::before': {
    height: '1px',
    content: '""',
    opacity: 1,
    backgroundColor: theme.palette.divider,
  },
}));

function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <NavBar />
      <div className="faq-container">
        <h1 className="faq-heading">Frequently Asked Questions</h1>
        <div className="accordian-faq" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {faqs.map((faq, index) => (
            <StyledAccordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              style={{ boxShadow: 'none' }}
              key={index}
            >
              <AccordionSummary expandIcon={<FaCaretDown />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                <Typography style={{ fontWeight: '600' }}>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontWeight: '400', fontSize: '16px' }}>{faq.answer}</Typography>
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </div>
      </div>
      <CTA />
      <Footer />
    </div>
  );
}

export default FAQ;
