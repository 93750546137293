import React, { useState, useEffect, useRef } from 'react';
import { uploadData, getProperties } from 'aws-amplify/storage';
import { v4 as uuidv4 } from 'uuid';
import { FaFileUpload, FaExclamationTriangle, FaCircle, FaCheckCircle } from 'react-icons/fa';
import './FileUploader.css';
import LinearDeterminate from '../ui-components/LinearDeterment';
import getUserData from '../userManagement/getUserData';
import styled from 'styled-components';
import { getDocument } from 'pdfjs-dist/build/pdf';
import { PDFWorker } from 'pdfjs-dist/build/pdf.worker';
import { calculateCredits } from './calculateCredits';
import GenerationConfirmation from '../ui-components/GenerationConfirmation';
import { Spinner } from 'react-bootstrap';
import updateUserUsage from '../userManagement/updateUsage';
import CustomInstructionsForm from './CustomInstructionsModal';




function FileUpload({ onUploadSuccess, onCreditsNeededChange, isFirstMindMap, userID, chartType }) {
    const [loading, setLoading] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [currentMonthUsage, setCurrentMonthUsage] = useState(0);
    const [MonthlyLimit, setMonthlyLimit] = useState(10);
    const [currentPlan, setCurrentPlan] = useState('');
    const [isPaidMember, setIsPaidMember] = useState(false);
    const [fileName, setFileName] = useState('');
    const [userData, setUserData] = useState();
    const [isUpgradeLoading, setIsUpgradeLoading] = useState(false);
    const [currentPageCount, setCurrentPageCount] = useState(0);
    const [currentCredits, setCurrentCredits] = useState(0);
    const [creditsNeeded, setCreditsNeeded] = useState(0);
    const [showGenerationConfirmation, setShowGenerationConfirmation] = useState(false);
    const [confirmationCallback, setConfirmationCallback] = useState(null);
    const isUploadDisabled = (currentMonthUsage >= MonthlyLimit && !isPaidMember);
    const [isFreePlan, setIsFreePlan] = useState(false);
    const [isPricingPopupOpen, setPricingPopupOpen] = useState(false); // New state for modal visibility
    const fileInputRef = React.createRef();
    const [showCustomInstructionsModal, setShowCustomInstructionsModal] = useState(false);
    const [showCustomInstructionsQuestionModal, setShowCustomInstructionsQuestionModal] = useState(false);
    const [isCalculatingCredits, setIsCalculatingCredits] = useState(false);
    const [resetDate, setResetDate] = useState('');
    const fileToUpload = useRef();
    const [calculatedCreditsNeeded, setCalculatedCreditsNeeded] = useState(0);


    const handleConfirm = async () => {
        setShowGenerationConfirmation(false); // Close the confirmation modal
        setShowCustomInstructionsQuestionModal(true);
};

const handleCustomInstructionsDecision = async (addInstructions) => {
    setShowCustomInstructionsQuestionModal(false); // Close the question modal

    if (addInstructions) {
        // User wants to add custom instructions, show the custom instructions form
        setShowCustomInstructionsModal(true);
    } else {
        // User does not want to add custom instructions, proceed with file upload
        setShowCustomInstructionsModal(false);
        if (fileToUpload.current) {
            await uploadFile(fileToUpload.current);
            await updateUserUsage(creditsNeeded);
            const userData = await getUserData();
            setCurrentMonthUsage(userData.userUsage.CurrentMonthUsage);
        }
    }
};


// Inside FileUpload component
const handleCustomFormSubmit = async (answers) => {

    // Close the custom instructions modal
    setShowCustomInstructionsModal(false);

    // Now proceed to upload the file with the answers as metadata
    if (fileToUpload.current) {
        await uploadFile(fileToUpload.current, answers); // Modify uploadFile to accept metadata
        await updateUserUsage(creditsNeeded);
        const userData = await getUserData();
        setCurrentMonthUsage(userData.userUsage.CurrentMonthUsage);
    }
};

    const togglePricingPopup = () => {         //to toggle state of the pricing component when cliclking on upgrade button
        setPricingPopupOpen(!isPricingPopupOpen);
    };

    const closePricingModal = () => {
        setPricingPopupOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserData();
            setUserData(data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserData();
            setCurrentPlan(userData.MembershipPlan);
            setCurrentMonthUsage(userData.userUsage.CurrentMonthUsage);
            setMonthlyLimit(userData.userUsage.MonthlyLimit);
            setIsPaidMember(userData.MembershipPlan === 'Paid' || userData.MembershipPlan === 'Starter Plan' || userData.MembershipPlan === 'Pro Plan' || userData.MembershipPlan === 'Annual Pro Plan' || userData.MembershipPlan === 'Pay Per Use');
            setIsFreePlan(userData.MembershipPlan === 'Freemium');
            setResetDate(userData.userUsage.ResetDate);
        };
        fetchData();
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        if (isUploadDisabled) return;
        const droppedFile = e.dataTransfer.files[0];
        processFile(droppedFile);
    };

    const handleChange = (event) => {
        processFile(event.target.files[0]);
    };

    const handleClick = () => {
        if (isUploadDisabled) return;
        fileInputRef.current.click();
    };

    const resetFileUploadState = () => {
        setLoading(false);
        setDragOver(false);
        setUploadError('');
        setUploadProgress(0);
        setCurrentPageCount(0);
        setFileName('');
            // Reset the file input
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
      };

    const handleClose = () => {
        setShowGenerationConfirmation(false);
        resetFileUploadState();
      };

    const UpgradeButton = styled.button`
        background-color: #c880ffff;
        border: none;
        border-radius: 42px;
        padding: 10px 30px;
        color: white;
        font-size: 16px;
        font-weight:700;
        cursor: pointer;
        margin-right:20px;
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        &:hover {
            background-color:#f370ffff;
        }
        `;

        const waitForUserConfirmation = (creditsNeeded, currentCredits) => {
            return new Promise((resolve) => {
              const handleOnClose = (userDecision) => {
                setShowGenerationConfirmation(false);
                resolve(userDecision);
              };
          
              setCreditsNeeded(creditsNeeded);
              setCurrentCredits(currentCredits);
              setShowGenerationConfirmation(true);
              setConfirmationCallback(() => handleOnClose); // Store the callback
            });
          };          

          const processFile = async (selectedFile) => {
            fileToUpload.current = selectedFile;
            if (selectedFile) {
                setFileName(selectedFile.name);
                try {
                    setIsCalculatingCredits(true);
                    const text = await extractTextFromPdf(selectedFile); // Extract text from the PDF
                    
                    // Check if the extracted text is empty
                    if (!text.trim()) {
                        // If there is no readable text in the file
                        setUploadError('No readable text found in the file. Please use another file or paste your text in "From Text" section.');
                        setIsCalculatingCredits(false);
                        return; // Exit the function early
                    }
        
                    const apiResponse = await callApiGateway(text); // Call the API Gateway with the extracted text
                    setIsCalculatingCredits(false);
        
                    // Check if apiResponse is valid and has a body field
                    if (apiResponse && apiResponse.body) {
                        const responseBody = JSON.parse(apiResponse.body); // Parse the body to get a JavaScript object
        
                        if (responseBody.numTokens !== undefined) {
                            const calculatedCreditsNeeded = calculateCredits(responseBody.numTokens, isFirstMindMap);
                            const newCurrentCredits = MonthlyLimit - currentMonthUsage;
                            
                            onCreditsNeededChange(calculatedCreditsNeeded);
        
                            const userHasConfirmed = await waitForUserConfirmation(calculatedCreditsNeeded, newCurrentCredits); 
                       
                            if (userHasConfirmed) {
                                // User confirmed, continue with file upload
                                uploadFile(selectedFile);
                            } else {
                                // User cancelled, do not upload the file
                            }
                        } else {
                            console.error('Invalid response received from API Gateway:', apiResponse);
                        }
                    } else {
                        console.error('Invalid or empty response received from API Gateway:', apiResponse);
                    }
                } catch (error) {
                    setUploadError('Error processing PDF or calling API Gateway: ' + error.message);
                    setIsCalculatingCredits(false);
                }
            }
        };        
    const extractTextFromPdf = async (file) => {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
            fileReader.onload = async (event) => {
                try {
                    const typedArray = new Uint8Array(event.target.result);
                    const loadingTask = getDocument({ data: typedArray });
                    const pdf = await loadingTask.promise;
                    let text = '';
    
                    for (let i = 0; i < pdf.numPages; i++) {
                        const page = await pdf.getPage(i + 1);
                        const textContent = await page.getTextContent();
                        text += textContent.items.map(item => item.str).join(' ');
                    }
    
                    resolve(text);
                } catch (err) {
                    reject(err);
                }
            };
            fileReader.readAsArrayBuffer(file);
        });
    };

    const handleUpgradeNavigation = () => {
        window.open('/pricing', '_blank');
      }
    
    const callApiGateway = async (text) => {
        try {
            let apiUrl = 'https://nrwqrsowv3.execute-api.us-east-1.amazonaws.com/dev';
            if (window.location.hostname === 'localhost') {
                apiUrl = 'https://vhf1skrzek.execute-api.us-east-1.amazonaws.com/dev'; // Replace with your local API URL
            }
            
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ text: text })
            });
            const data = await response.json();
            //console.log(data);
            return data;
        } catch (error) {
          console.error('Error calling API Gateway:', error);
          return null;
        }
      };
    

    const uploadFile = async (file, customInstructions = {}) => {
        //console.log('Starting file upload process...');
        setLoading(true);
        setUploadProgress(0);
        const uniqueFileIdentifier = uuidv4();
        const uniqueFileName = `${uniqueFileIdentifier}-${file.name}`; 

        // Prepare metadata from customInstructions
        const metadata = Object.entries(customInstructions).reduce((acc, [key, value]) => {
            // For each question-answer pair, add an entry to the metadata object
            acc[`question_${key}`] = value;
            return acc;
        }, {});

        // Add userID and creditsNeeded to metadata
        metadata.userID = userID;
        metadata.creditsNeeded = creditsNeeded;
        metadata.chart_type = chartType;


        try {
            //console.log('Attempting to upload file...');
            const uploadTask = await uploadData({
                key: uniqueFileName,
                data: file,
                options: {
                    accessLevel: 'private',
                    metadata: {...metadata}, // Or 'protected'/'guest' as per your configuration
                    onProgress: ({ transferredBytes, totalBytes }) => {
                        const progress = Math.round((transferredBytes / totalBytes) * 100);
                        //console.log(`Upload progress: ${progress}%`);
                        setUploadProgress(progress);
    
                        // Directly call onUploadSuccess when progress reaches 100%
                        if (progress === 100) {
                            //console.log('Upload progress is 100%. File upload assumed successful.');
                            if (onUploadSuccess) {
                                onUploadSuccess(uniqueFileName);
                            }
                        }
                    }
                }
            }).result; // Ensure that 'result' is the correct way to access the upload response based on your 'uploadData' function's implementation
            //console.log('File upload completed.');
        } catch (error) {
            //console.log(`Error during file upload process: ${error.message}`);
            setUploadError(`Error uploading file: ${error.message || 'Unknown error'}`);
        } finally {
            setLoading(false);
            //console.log('File upload process finished.');
        }
    };
    

    return (
        <div className="file-uploader-wrapper">
            {showGenerationConfirmation ? (
                <div className="confirmation-modal-overlay">
                    <GenerationConfirmation
                        membershipPlan={userData.MembershipPlan}
                        monthlyLimit={MonthlyLimit}
                        creditsNeeded={creditsNeeded}
                        currentCredits={currentCredits}
                        onClose={handleClose}
                        isPaidMember={isPaidMember}
                        resetDate={resetDate}
                        onConfirm={handleConfirm}
                    />
                </div>
            ) : (
                    <div style={{ display: (showCustomInstructionsQuestionModal || showCustomInstructionsModal) ? 'none' : 'block' }} className={`file-uploader ${dragOver ? 'drag-over' : ''}`}
                     onDragOver={handleDragOver}
                     onDragLeave={handleDragLeave}
                     onDrop={handleDrop}
                     onClick={handleClick}>
                    <div className="file-uploader-content">
                        {uploadError && (
                            <div className="file-uploader-error">
                                <FaExclamationTriangle className="file-uploader-error-icon" />
                                <p>{uploadError}</p>
                            </div>
                        )}
                        {(isPaidMember) ? (
                            <>
                            {!isUploadDisabled && (
                                <>
                                <input 
                                    type="file" 
                                    accept=".pdf" 
                                    onChange={handleChange} 
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                {loading && <LinearDeterminate progress={uploadProgress} />}
                                {!loading && !fileName && (
                                    <div className="file-uploader-instructions">
                                        <FaFileUpload />
                                         Upload your PDF
                                    </div>
                                      
                                )}
                                {!loading && !fileName && (
                                    <div className="file-uploader-description">
                                        <p>We recommend using PDF no longer than 50 pages for better results</p>
                                    </div>
                                    
                                    
                                )}
                                {fileName && (
                                    <div>
                                        <p className="file-uploader-filename">
                                            {uploadError ? 'File not uploaded' : `Uploaded File: ${fileName}`}
                                        </p>
                                        {isCalculatingCredits && (
                                            <div className="calculating-credits-loader">
                                                <Spinner animation="border" role="status" />
                                                <span>Calculating Credits required...</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                                </>
                            )}

                            {isUploadDisabled && (
                                    <>
                                            <div className="file-uploader-alert">
                                                <FaExclamationTriangle className="file-uploader-alert-icon" />
                                                <p>You have reached your monthly limit of {MonthlyLimit} uploads. Please upgrade to continue.</p>
                                                <UpgradeButton
                                                    onClick={togglePricingPopup}
                                                    disabled={isUpgradeLoading}
                                                >
                                                    {isUpgradeLoading ? 'One moment...' : 'Upgrade to Pro!'}
                                                </UpgradeButton>
                                            </div>
                                            </>
                                        )}
                                
                            </>
                        ) : (
                            <div className="file-uploader-alert">
                            <FaExclamationTriangle className="file-uploader-alert-icon" />
                            <p>You do not have access to the upload feature. Please upgrade to access file uploads.</p>
                            <UpgradeButton
                                onClick={handleUpgradeNavigation}
                                disabled={isUpgradeLoading}
                            >
                                {isUpgradeLoading ? 'One moment...' : 'Upgrade to Pro!'}
                            </UpgradeButton>
                        </div>
                    )}
                    </div>
                </div>
            )}
            {showCustomInstructionsQuestionModal && (
                <div className="custom-instructions-container">
                <div className="row-section">
                    <div className="image-section">
                        <img src={`${process.env.PUBLIC_URL}/customInstructions.png`} alt="Custom Instructions" />
                    </div>
                    <div className="text-section">
                        <h3>Do you want to add custom instructions?</h3>
                    </div>
                </div>
                <p style={{paddingTop: '10px', color: 'rgba(120,120,120)'}}>Custom instructions will help us create a mindmap that is tailored to your needs.</p>
                <div className="button-section">
                    <button className="no-btn" onClick={() => handleCustomInstructionsDecision(false)}> Continue without instructions <img src={`${process.env.PUBLIC_URL}/warning-circle.svg`} alt="Continue without instructions" /> </button>
                    <button className="yes-btn" onClick={() => handleCustomInstructionsDecision(true)}> Add instructions </button>
                </div>

            </div>
            )}

            {showCustomInstructionsModal && (
                <CustomInstructionsForm onSubmit={handleCustomFormSubmit} />
            )}

        </div>
    );
}

export default FileUpload;