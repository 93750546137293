import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from 'react-icons/fa';
import styled from 'styled-components';

// Styled components for the Footer
const StyledFooter = styled.div`
  align-items: center;
  background-color: #002b31;
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 80px 64px 0px;
  position: relative;
  width: auto; // Set to 100% to take up full viewport width
  border-bottom: 3px solid #0f91a3;
  @media (max-width: 768px) {
    padding: 40px 20px 0px; // Reduced padding for mobile screens
    gap: 30px; // Reduced gap
  }
`;
const FooterDiv = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: row; // Stack elements vertically
    align-items: center; // Center-align items
  }
`;

const FooterColumn = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column; // Stack elements vertically
    align-items: flex-start; // Center-align items
    margin-bottom: 20px; // Add some space at the bottom
  }
`;

const LogoImage = styled.img`
  height: 48.12px;
  position: relative;
  width: 143.88px;
`;

const NavBar = styled.div`
  align-items: flex-start;
  display: inline-flex;
  gap: 32px;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column; // Stack links vertically
    gap: 10px; // Reduce gap between links
  }
`;

const Link = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Actions = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 400px;
  @media (max-width: 768px) {
    width: auto; // Adjust width for mobile screens
    align-items: center; // Center-align items
  }
`;

const Heading = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
`;

const Form = styled.form`
  display: flex;
  gap: 16px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column; // Stack form elements vertically
  }
`;

const TextInput = styled.input`
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 90px;
  color: #333;
  font-size: 13px;
  height: 50px;
  padding: 12px 20px;
  width: 100%;
  &::placeholder {
    color: #666;
  }
`;

const SubscribeButton = styled.button`
  background: linear-gradient(180deg, rgb(213, 198, 241) 0%, rgb(246, 174, 177) 100%);
  border: none;
  border-radius: 120px;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 24px;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  @media (max-width: 768px) {
    width: 100%; // Full width button on mobile
  }
`;

const Text = styled.p`
  color: #ffffff;
  font-size: 12px;
`;

const Span = styled.span`
  &:nth-child(3) {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`;

const Credits = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center; // Center-align items
  }
`;

const Divider = styled.div`
  background-color: #ffffff;
  height: 1px;
  width: 100%;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end; // Align links to the right
  @media (max-width: 768px) {
    flex-direction: row; // Stack links vertically
    gap: 10px; // Reduce gap between links
  }
`;

const TextWrapper = styled.span`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const Rectangle = styled.div`
  background: linear-gradient(180deg, rgb(213, 198, 241) 0%, rgb(246, 174, 177) 100%);
  height: 33px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const CopyRight = styled.p`
  color: #ffffff;
  font-size: 14px;
  margin-top: 1em; // Adjust as needed
  text-align: center;
`;

// Footer Component
export const Footer = () => {
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <StyledFooter>
      <FooterDiv>
        <FooterColumn>
         <LogoImage src={`${process.env.PUBLIC_URL}/logo.png`} alt="Map This Logo" />
          <NavBar>
            <Link onClick={() => openInNewTab('https://airtable.com/appRgfGSKMliHxHSS/pagA4yYekAO500uNH/form')}>Contact Us</Link>
            <Link onClick={() => openInNewTab('/privacy-policy')}>Privacy Policy</Link>
            <Link onClick={() => openInNewTab('/faq')}>FAQ</Link>
            <Link onClick={() => openInNewTab('https://map-this.getrewardful.com/signup')}>Become an Affiliate</Link> 
            <Link onClick={() => openInNewTab('/about-us')}>About Us</Link>
          </NavBar>
        </FooterColumn>
        <Actions>
          <Heading>Our Socials</Heading>
          <FooterLinks>
            <Link onClick={() => openInNewTab('https://www.youtube.com/channel/UCMHuQzCRvhiV2sFP2aEtDrg')}><FaYoutube/></Link>
            <Link onClick={() => openInNewTab('https://www.instagram.com/productivity_unhinged')}><FaInstagram/></Link>
            <Link onClick={() => openInNewTab('https://www.linkedin.com/company/map-this-app/')}><FaLinkedin/></Link>
            <Link onClick={() => openInNewTab('https://www.tiktok.com/@mapthisapp')}><FaTiktok/></Link>
          </FooterLinks>
        </Actions>
      </FooterDiv>
      <Credits>
        <Divider />
        <CopyRight>© 2023 map-this. All rights reserved.</CopyRight>
      </Credits>
      <Rectangle />
    </StyledFooter>
  );
};
export default Footer;