import React, { useMemo } from "react";
import styled from "styled-components";
import NavBar from "../ui-components/LandingPage/Navbar"; // Header component
import Footer from "../ui-components/LandingPage/Footer"; // Footer component

// Styled-components for the team container and images
const TeamPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures footer stays at the bottom */
`;

const HeaderSection = styled.div`
  text-align: center;
  margin: 30px 0;
`;

const TeamHeader = styled.h1`
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
`;

const TeamContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
`;

const TeamMember = styled.div`
  margin: 20px;
  text-align: center;
`;

const TeamLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const TeamImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  filter: grayscale(100%); /* Make image black and white */
  transition: filter 0.3s ease; /* Smooth transition on hover */

  &:hover {
    filter: grayscale(0%); /* Remove black and white on hover */
  }
`;

const TeamName = styled.h3`
  margin-top: 10px;
  font-size: 18px;
`;

const TeamPosition = styled.p`
  font-size: 14px;
  color: #777;
`;

const AboutTeam = () => {
  // Team members array
  const teamMembers = [
    {
      name: "Kushank Aggarwal",
      position: "Chief Hype Officer",
      imageUrl: "/team/kushank.jpg",
      linkedIn: "https://www.linkedin.com/in/kushankaggarwal/",
    },
    {
      name: "Natik Aggarwal",
      position: "Chief What to Build Officer",
      imageUrl: "/team/natik.png",
      linkedIn: "https://www.linkedin.com/in/natikaggarwal/",
    },
    {
      name: "Arunav Goel",
      position: "Chief AI Officer",
      imageUrl: "/team/arunav.png",
      linkedIn: "https://www.linkedin.com/in/arunavgoel/",
    },
    {
      name: "Julia Sippert",
      position: "Chief Let Me Help Officer",
      imageUrl: "/team/julia.png",
      linkedIn: "https://www.linkedin.com/in/julia-sippert-665b241ba/",
    },
  ];

  // Shuffle team members using useMemo to ensure it's done only once per render
  const shuffledTeamMembers = useMemo(() => {
    return [...teamMembers].sort(() => 0.5 - Math.random());
  }, [teamMembers]);

  return (
    <TeamPageContainer>
      {/* Header */}
      <NavBar />

      {/* Page Title Section */}
      <HeaderSection>
        <TeamHeader>Meet the Team</TeamHeader>
      </HeaderSection>

      {/* Main Content - Team Members */}
      <TeamContainer>
        {shuffledTeamMembers.map((member) => (
          <TeamMember key={member.name}>
            {/* Make the image and name clickable */}
            <TeamLink href={member.linkedIn} target="_blank" rel="noopener noreferrer">
              <TeamImage src={member.imageUrl} alt={member.name} />
              <TeamName>{member.name}</TeamName>
              <TeamPosition>{member.position}</TeamPosition>
            </TeamLink>
          </TeamMember>
        ))}
      </TeamContainer>

      {/* Footer */}
      <Footer />
    </TeamPageContainer>
  );
};

export default AboutTeam;
