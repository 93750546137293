import React, { useEffect, useRef, useState } from 'react';
import './UseCases.css' // Include necessary styles

const useCasesData = [
    {
      id: 1,
      image: '/useCases/book-helper-icon.png', // Add appropriate image URLs here
      heading: 'Note Buddy',
      description: 'Transform messy class notes into colorful mind maps. Easily organize complex topics, highlight key concepts, and create visual connections to boost your learning and retention.',
      link: '/login'
    },
    {
      id: 2,
      image: '/useCases/task-tracker-icon.png',
      heading: 'Task Tracker',
      description: 'Convert chaotic to-do lists into clear, actionable flowcharts. Prioritize tasks, visualize dependencies, and track progress effortlessly to maximize your productivity.',
      link: '/login'
    },
    {
      id: 3,
      image: '/useCases/book-helper-icon.png',
      heading: 'Book Helper',
      description: 'Turn dense book summaries into engaging visual concept maps. Capture main ideas, character relationships, and plot points at a glance, enhancing comprehension and recall.',
      link: '/login'
    },
    {
      id: 4,
      image: '/useCases/meeting-mapper-icon.png',
      heading: 'Meeting Mapper',
      description: 'Create structured diagrams from jumbled meeting minutes. Clearly outline decisions, action items, and discussion points to ensure everyone\'s on the same page post-meeting.',
      link: '/login'
    },
    {
      id: 5,
      image: '/useCases/essay-planner-icon.png',
      heading: 'Essay Planner',
      description: 'Build comprehensive essay outlines into intuitive branching mind maps. Organize your thoughts, support your arguments, and visualize your essay structure for better writing flow.',
      link: '/login'
    },
    {
      id: 6,
      image: '/useCases/recipe-wizard-icon.png',
      heading: 'Recipe Wizard',
      description: 'Transform text-heavy recipes into easy-to-follow visual cooking guides. Break down ingredients, steps, and timing into a clear format that makes meal prep a breeze.',
      link: '/login'
    },
    {
      id: 7,
      image: '/useCases/travel-buddy-icon.png',
      heading: 'Travel Buddy',
      description: 'Convert detailed itineraries into exciting visual journey maps. Plot your adventures, highlight key attractions, and visualize your travel route for better trip planning and sharing.',
      link: '/login'
    },
    {
      id: 8,
      image: '/useCases/code-visualizer-icon.png',
      heading: 'Code Visualizer',
      description: 'Turn complex code documentation into clear, logical flowcharts. Simplify software architecture, illustrate processes, and improve team understanding of system functionality.',
      link: '/login'
    },
    {
      id: 9,
      image: '/useCases/story-builder-icon.png',
      heading: 'Story Builder',
      description: 'Transform scattered story ideas into rich character and plot maps. Develop your narrative, track character arcs, and visualize story structure to enhance your creative writing process.',
      link: '/login'
    },
    {
      id: 10,
      image: '/useCases/project-pal-icon.png',
      heading: 'Project Pal',
      description: 'Convert detailed project plans into easy-to-read Gantt charts. Visualize timelines, track milestones, and manage resources effectively for smoother project execution.',
      link: '/login'
    },
    {
      id: 11,
      image: '/useCases/exam-explorer-icon.png',
      heading: 'Exam Explorer',
      description: 'Convert lengthy study guides into interactive concept maps. Break down complex subjects, visualize relationships between topics, and create a comprehensive overview for effective exam preparation.',
      link: '/login'
    },
    {
      id: 12,
      image: '/useCases/lab-report-launcher-icon.png',
      heading: 'Lab Report Launcher',
      description: 'Transform raw experimental data and observations into clear, visual lab reports. Organize methods, results, and conclusions into flowcharts and diagrams for more impactful scientific communication.',
      link: '/login'
    },
    {
      id: 13,
      image: '/useCases/history-timeline-creator-icon.png',
      heading: 'History Timeline Creator',
      description: 'Convert chronological events from your history notes into dynamic, visual timelines. Easily spot patterns, remember key dates, and understand the flow of historical events for better recall in exams.',
      link: '/login'
    },
    {
      id: 14,
      image: '/useCases/math-problem-solver-icon.png',
      heading: 'Math Problem Solver',
      description: 'Turn word problems into step-by-step visual solution maps. Break down complex mathematical concepts, visualize problem-solving steps, and improve your understanding of formulas and their applications.',
      link: '/login'
    },
    {
      id: 15,
      image: '/useCases/literature-analysis-assistant-icon.png',
      heading: 'Literature Analysis Assistant',
      description: 'Create character relationship maps and plot diagrams from your literature notes. Visualize character development, track themes, and analyze story arcs for deeper understanding of literary works.',
      link: '/login'
    },
    {
      id: 16,
      image: '/useCases/language-learning-buddy-icon.png',
      heading: 'Language Learning Buddy',
      description: 'Transform vocabulary lists and grammar rules into interactive language maps. Create visual connections between words, phrases, and sentence structures to accelerate your language learning process.',
      link: '/login'
    },
    {
      id: 17,
      image: '/useCases/project-planner-icon.png',
      heading: 'Project Planner',
      description: 'Convert group project guidelines into clear task distribution flowcharts. Assign roles, set deadlines, and visualize project milestones to ensure smooth collaboration and timely completion of team assignments.',
      link: '/login'
    },
    {
      id: 18,
      image: '/useCases/book-helper-icon.png',
      heading: 'Research Paper Organizer',
      description: 'Turn research notes and citations into structured outline maps. Organize your arguments, link supporting evidence, and create a clear flow of ideas for more cohesive and well-structured academic papers.',
      link: '/login'
    }
  ];
  

  const UseCases = () => {
    const containerRef = useRef(null);
    const scrollIntervalRef = useRef(null);
  
    const startScrolling = () => {
      if (scrollIntervalRef.current) return; // Don't start if it's already running
      const scrollContainer = containerRef.current;
  
      scrollIntervalRef.current = setInterval(() => {
        if (scrollContainer) {
          scrollContainer.scrollLeft += 1; // Adjust speed here
  
          // Infinite scrolling logic
          if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
            scrollContainer.scrollLeft = 0; // Reset scroll to start
          }
        }
      }, 20); // Adjust speed here
    };
  
    const stopScrolling = () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
        scrollIntervalRef.current = null;
      }
    };
  
    useEffect(() => {
      startScrolling(); // Start scrolling on component mount
  
      return () => {
        stopScrolling(); // Clear interval on component unmount
      };
    }, []);
  
    const handleMouseEnter = () => {
      stopScrolling();
    };
  
    const handleMouseLeave = () => {
      startScrolling();
    };
  
    return (
        <div className="use-cases-wrapper">
          <h2 className="use-cases-heading">Popular Uses</h2>
          <div
            className="use-cases-container"
            ref={containerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* Render original cards */}
            {useCasesData.map((useCase) => (
              <div className="use-case-card" key={useCase.id}>
                <div className="image-container">
                <img src={useCase.image} alt={useCase.heading} className="use-case-image" />
                </div>
                <div classNmae="heading-container">
                <h3 className="use-case-heading">{useCase.heading}</h3>
                </div>
                <p className="use-case-description">{useCase.description}</p>
                <a href={useCase.link} className="try-now-button">Try Now</a>
              </div>
            ))}
    
            {/* Clone cards for infinite scrolling */}
            {useCasesData.map((useCase, index) => (
              <div className="use-case-card" key={`clone-${index}`}>
                <div className="image-container">
                <img src={useCase.image} alt={useCase.heading} className="use-case-image" />
                </div>
                <div classNmae="heading-container">
                <h3 className="use-case-heading">{useCase.heading}</h3>
                </div>
                <p className="use-case-description">{useCase.description}</p>
                <a href={useCase.link} className="try-now-button">Try Now</a>
              </div>
            ))}
          </div>
        </div>
      );
    };
    
    export default UseCases;