import React from 'react';
import './ToolCards.css'; // Import the CSS for styling

const tools = [
  { id: 1, title: 'PDF to Mindmap', link: '/tools/pdf-to-mindmap', image: '/tools-icons/pdf-to-mindmap.png' },
  { id: 2, title: 'Notes to Mindmap', link: '/tools/notes-to-mindmap', image: '/tools-icons/notes-to-mindmap.png' },
  { id: 3, title: 'AI Magic to Mindmap', link: '/tools/ai-magic-to-mindmap', image: '/tools-icons/ai-magic-to-mindmap.png' },
  { id: 4, title: 'PDF to Gantt', link: '/tools/pdf-to-gantt', image: '/tools-icons/pdf-to-gantt.png' },
  { id: 5, title: 'Notes to Gantt', link: '/tools/notes-to-gantt', image: '/tools-icons/notes-to-gantt.png' },
  { id: 6, title: 'PDF to Flowchart', link: '/tools/pdf-to-flowchart', image: '/tools-icons/pdf-to-flowchart.png' },
  { id: 7, title: 'Notes to Flowchart', link: '/tools/notes-to-flowchart', image: '/tools-icons/notes-to-flowchart.png' },
  { id: 8, title: 'AI Magic to Flowchart', link: '/tools/ai-magic-to-flowchart', image: '/tools-icons/ai-magic-to-flowchart.png' },
];

const ToolCards = () => {
    return (
        <div className="tool-cards-container">
          <h1 className="tool-cards-heading">All Tools</h1> {/* Added class to h1 */}
          <div className="tool-cards">
            {tools.map((tool) => (
              <div key={tool.id} className="tool-card">
                <a style={{ textDecoration: "none" }} href={tool.link}>
                  <img src={tool.image} alt={tool.title} className="tool-icon" />
                  <h4 className="tool-card-title">{tool.title}</h4> {/* Added class to h2 */}
                </a>
              </div>
            ))}
          </div>
        </div>
      );
};

export default ToolCards;
