// store/diagramStore.js

import { uploadData } from '@aws-amplify/storage';
import { create } from 'zustand';
import { nanoid } from 'nanoid';

const useReactFlowFileStore = create((set, get) => ({
  files: {}, // Object to hold files with filename as the key
  nodes: [], // Initialize nodes as an empty array
  edges: [], // Initialize edges as an empty array

  // Action to retrieve a file's content by its name
  getFile: (filename) => {
    return get().files[filename] || null;
  },

  // Add a new child node and edge
  addChildNode: (parentNode, position) => {
    //console.log('Adding child node to parent:', parentNode, 'at position:', position);
  
    const newNode = {
      id: nanoid(),
      type: 'mindmap',
      data: { label: 'New Node' },
      position,
      parentNode: parentNode.id, // Link the child to the parent
    };
  
    const newEdge = {
      id: nanoid(),
      source: parentNode.id,
      target: newNode.id,
      type: 'mindmap',
    };
  
    set((state) => {
      // Add the new node and edge to the state
      const updatedNodes = [...state.nodes, newNode];
      const updatedEdges = [...state.edges, newEdge];
  
      // Update Zustand state with new nodes and edges
      return {
        nodes: updatedNodes,
        edges: updatedEdges,
      };
    });
  
    // Get the current file content and filename
    const fileName = Object.keys(get().files)[0]; // Assuming there's only one file in use
    const currentFile = get().files[fileName];
    const parsedFile = currentFile ? JSON.parse(currentFile) : { nodes: [], edges: [] };
  
    // Update the file's content with the new nodes and edges
    const updatedFileContent = {
      nodes: [...parsedFile.nodes, newNode],
      edges: [...parsedFile.edges, newEdge],
    };
  
    // Save the updated file to the store
    get().addOrUpdateFile(fileName, JSON.stringify(updatedFileContent));
  },
  

  // Action to add or update a file
  addOrUpdateFile: (filename, content) => {
    set((state) => ({
      files: {
        ...state.files,
        [filename]: content,
      },
    }));
  },

  // Action to update node label
  updateNodeLabel: (nodeId, newLabel) => {
    set((state) => {
      const fileName = Object.keys(state.files)[0]; // Assuming there's only one file in use
      const parsed = JSON.parse(state.files[fileName]); // Parse the file's content (nodes and edges)

      // Update the label of the matching node
      const updatedNodes = parsed.nodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, label: newLabel } } : node
      );

      const updatedFile = {
        ...parsed,
        nodes: updatedNodes,
      };

      return {
        files: {
          ...state.files,
          [fileName]: JSON.stringify(updatedFile),
        },
      };
    });
  },

  // Action to save the file and upload it to S3 using uploadData
  saveFile: async (filename) => {
    const fileContent = get().files[filename];
    console.log("File triggered for save in Zustand store:", filename);

    if (fileContent) {
      try {
        const fileKey = filename; // Use the filename as the S3 key
        //console.log("Starting file upload to S3. File Key:", fileKey, "File Content:", fileContent);

        const file = new File([fileContent], fileKey, { type: 'text/plain' });

        // Use uploadData to upload the file to S3
        const result = uploadData({
          path: fileKey, // The key under which the file will be stored in S3
          data: file, // The content of the file
          options: { accessLevel: 'private' }, // Set the access level
        });

        // Log the response/result from the upload operation
        //console.log(`File "${filename}" successfully uploaded to S3. S3 Result:`, result);
      } catch (error) {
        // Log detailed error information
        console.error(`Error uploading file "${filename}" to S3:`, error);
      }
    } else {
      //console.log(`File "${filename}" does not exist in the store.`);
    }
  },
}));

export default useReactFlowFileStore;
