import React from 'react';
import { toPng } from 'html-to-image';
import { useReactFlow } from 'reactflow';

// Function to handle image download
const downloadImage = (dataUrl) => {
  const link = document.createElement('a');
  link.download = 'map-this-mindmap.png';
  link.href = dataUrl;
  link.click();
};

const DownloadButton = () => {
  const { getNodes, fitView } = useReactFlow();

  const onClick = () => {
    // First, zoom out to fit all nodes into view
    fitView({ padding: 0.1, includeHiddenNodes: true });

    // Once the nodes are zoomed out to fit, capture the viewport
    setTimeout(() => {
      toPng(document.querySelector('.react-flow__viewport'), {
        backgroundColor: '#ffffff',
      })
        .then(downloadImage)
        .catch((error) => {
          console.error('Error generating image:', error);
        });
    }, 300); // Add a small delay to allow the zoom out to complete
  };

  return (
    <button className="download-btn" onClick={onClick} style={{ backgroundColor: 'white', border: 'none', padding: '5px', cursor: 'pointer' }}>
      <img src="/icons8-download-90.png" alt="Download Image" style={{ width: '18px', height: '18px' }} />
    </button>
  );
};

export default DownloadButton;
