import React from "react";
import { toolPageConfig } from "./toolPageConfig";
import NavBar from "../ui-components/LandingPage/Navbar";
import NewHero from "../ui-components/LandingPage/NewHero";
import WallOfLove from "../ui-components/LandingPage/WallOfLove";
import Footer from "../ui-components/LandingPage/Footer";
import UseCases from "../ui-components/UseCases";
import ToolCards from "../ui-components/ToolCards";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom
import './ToolPage.css';  // Create and import this CSS file for styling

const ToolPage = ({ type }) => {
  // Get the page configuration for the current type
  const config = toolPageConfig[type];

  if (!config) {
    return <div>Page not found</div>;  // Handle invalid type
  }

  return (
    <div>
      <NavBar />

      {/* Breadcrumb Element */}
      <div className="breadcrumb">
        <Link to="/" className="breadcrumb-link">
          Map This
        </Link>
        <span className="breadcrumb-separator"> &gt; </span>
        <Link to="/tools" className="breadcrumb-link">
          Tools
        </Link>
        <span className="breadcrumb-separator"> &gt; </span>
        <span>{config.content}</span>
      </div>

      <NewHero
        userInput={config.userInput}
        userOutput={config.userOutput}
        CTAText={config.CTAText}
        demoLink={config.demos}
      />
      <UseCases />
      <ToolCards />
      <WallOfLove />
      <Footer />
    </div>
  );
};

export default ToolPage;
