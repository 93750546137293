import React from 'react';

const RefundPolicyPage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.title}>Cancellation & Refund Policy</h1>
        <p style={styles.text}>
          We understand the importance of transparency regarding cancellations and refunds. 
          Subscriptions are recurring, and you will be automatically billed until you choose 
          to cancel. You can cancel at any time by logging into your account via your <a style={{textDecoration:'none'}} href='https://billing.stripe.com/p/login/3cs7uD2ML8MWdeEdQQ'>Stripe Portal</a> or your account dashboard under <strong>My Account -&gt; Subscription</strong> <strong>  -&gt; Manage Subscription</strong>. 
          Once canceled, no further charges will be applied.
        </p>
        <p style={styles.text}>
          Please note that due to the significant operating costs associated with our product, 
          payments for subscriptions are generally non-refundable. However, 
          if you believe your situation warrants a refund due to a bug or error in our product or service, 
          we encourage you to schedule a call with our support team. During this call, you will have the 
          opportunity to demonstrate the issue, and we will work to address and resolve any potential 
          problems to maintain our high-quality standards.
        </p>
        <p style={styles.text}>
          To initiate the refund request process, please contact our support team at&nbsp; 
          <a href="mailto:team@map-this.com" style={styles.link}>team@map-this.com</a>&nbsp;to schedule your call.
        </p>
        <p style={styles.text}>
          We appreciate your understanding and cooperation.
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    fontFamily: "'Times New Roman', Times, serif",
    color: '#333',
    padding: '30px',
    textAlign: 'center',
  },
  content: {
    maxWidth: '1000px',
    padding: '20px',
    textAlign: 'left',
  },
  title: {
    fontSize: '3em',
    fontWeight: 'bold',
    marginBottom: '20px',
    fontFamily: 'Georgia, serif',
  },
  text: {
    fontSize: '1.6em',
    marginBottom: '20px',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
  },
};

export default RefundPolicyPage;
