import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import userIcon from '../../assets/userIcon.png'
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faCoins } from '@fortawesome/free-solid-svg-icons';
import getUserData from '../../userManagement/getUserData';
import { loadStripe } from '@stripe/stripe-js';
import { handleUpgradeClick } from '../../util/HandleUpgrade';
import PricingComponent from '../PricingComponent';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getCurrentUser } from '@aws-amplify/auth';
import './SalesBanner.css';


const stripePromise = loadStripe('pk_live_51OJn7eGPqEG8TQso0zfoGWpGdFpsJ5IOw1f7FAEHIQwdfK5uFnxmdMvtaxcmbmVbZ5J6KYetwJwCnm8JkxTsPfd600d9j7ZsNO');

// Styled components for Navbar
const Navbar = styled.nav`
  background-color: #002b31;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: ${props => props.isLoginPage ? 'relative' : 'fixed'};
  top: 0; /* Ensure it's always at the top */
  left: 0; /* Align to the left edge of the viewport */
  right: 0; /* Align to the right edge of the viewport */
  height: 94px;
  border-bottom: 3px solid #0f91a3;
  z-index: 1000; /* Ensure it stays on top of other content */
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => props.isLoginPage ? '100px' : '0'};
`;


const Logo = styled.img`
  cursor: pointer;
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  height: 48px;
  @media (max-width: 768px) {
    left: 20px;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: 1.5px solid #ffffff;
  border-radius: 42px;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    background-color: #dddddd;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  align-items: center; // This will vertically center the children
  position: absolute;
  right: 80px;
  top: 0; // Align to the top of the Navbar
  bottom: 0; // Align to the bottom of the Navbar
  height: 94px; // Match the height of the Navbar
  @media (max-width: 768px) {
    display: none;
  }
`;

// Dropdown components
const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 80px;
  background-color: #f5f5f4;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 150px;
  color: #002b31;
  &:hover {
    background-color: #dddddd;
    cursor: pointer;
  }

  .icon {
    margin-right: 8px; // Add some space between the icon and the text
  }
`;

const UserIcon = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

const Tooltip = styled.span`
  visibility: hidden;
  width: 200px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top:100%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;

  /* Tooltip arrow */
  ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

const CreditsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: white;
  cursor: pointer;
  position: relative;

  &:hover ${Tooltip} {
    visibility: block;
    opacity: 1;
  }
`;


const CreditsText = styled.span`
  margin-left: 5px;
  margin-right:5px;
`;

const UpgradeButton = styled.button`
  background-color: #f0ad4e;
  border: none;
  border-radius: 42px;
  padding: 15px 40px;
  color: white;
  font-size: 16px;
  font-weight:700;
  cursor: pointer;
  margin-right:20px;
  &:hover {
    background-color: #ec971f;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
`;

// User Dropdown Component
const UserDropdown = ({ signOut, navData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(false); // New state for loading indicator
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleMyAccount = async () => {
    try {
      setIsAccountLoading(true);

      const user = await getCurrentUser();
      if (!user) {
        //console.error('User not found');
        throw new Error("User not found");
      }

      const userId = user.userId; // Assuming the username is the userId required by your backend

      const response = await fetch('https://daffzuzdjd.execute-api.us-east-1.amazonaws.com/dev/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      const responseBody = await response.text(); // Get the response body as text

      const data = JSON.parse(responseBody); // Parse the text as JSON

      if (response.ok) {
        const innerData = JSON.parse(data.body); // Parse the inner JSON string

        if (innerData.url) {
          window.location.href = innerData.url;
        } else {
          throw new Error('URL not found in response data');
        }
      } else {
        throw new Error(data.error || 'Failed to retrieve billing portal URL');
      }
    } catch (error) {
      //console.error('Error in handleMyAccount:', error);
      // Handle the error (e.g., show a notification to the user)
    } finally {
      setIsAccountLoading(false); // Reset loading state to false
    }
};

  const handleSignOut = () => {
    signOut();
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef}>
      <UserIcon src={userIcon} alt="User" onClick={() => setIsOpen(!isOpen)} />
      <DropdownMenu isOpen={isOpen}>
        {navData.currentPlan == "Paid" && (
          <DropdownItem onClick={handleMyAccount}>
            <FontAwesomeIcon icon={faUser} className="icon" />
            {isAccountLoading ? 'Loading...' : 'My Account'} {/* Update text based on loading state */}
          </DropdownItem>
        )}
        <DropdownItem onClick={handleSignOut}>
          <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
          Sign Out
        </DropdownItem>
      </DropdownMenu>
    </div>
  );
};

// Custom Hook to detect outside click
const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};



// Main Navbar Component
const NavBar = ({navData,  triggerUpdate, isLoginPage}) => {
  const [userData, setUserData] = useState({ userUsage: { MonthlyLimit: 0, CurrentMonthUsage: 0 } });
  const [isUpgradeLoading, setIsUpgradeLoading] = useState(false);
  const [isPricingPopupOpen, setPricingPopupOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isAppPage = location.pathname === '/app';
  const [isLoading, setIsLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0, hasSaleStarted: false });

  useEffect(() => {
    const calculateTimeLeft = () => {
        const targetDate = new Date('July 15, 2024 00:00:00 GMT-0700');
        const now = new Date();
        const difference = targetDate - now;

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);
            return { days, hours, minutes, seconds, hasSaleStarted: false };
        } else {
            return { days: 0, hours: 0, minutes: 0, seconds: 0, hasSaleStarted: true };
        }
    };

    // Set initial time left synchronously
    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
}, []);

  const togglePricingPopup = () => {
    setPricingPopupOpen(!isPricingPopupOpen);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleTools = () => {
    navigate('/tools')
  }

  const handleHome = () => {
    navigate('/');
  };


  useEffect(() => {
      const fetchData = async () => {
          const data = await getUserData();
          setUserData(data);
          setIsLoading(false);
      };
  
      fetchData();
  }, [triggerUpdate]);
  
  if (isLoading) {
    return (
      <Navbar isLoginPage={isLoginPage}>
        <Logo onClick={handleHome} src="/logo.png" alt="Map This Logo" />
      </Navbar>
    );
  }

  const handleClosePricingModal = () => {
    setPricingPopupOpen(false);
};
const pricingRedirect = () => {
  window.location.href = '/pricing';
};

  return (
<div>
    <Navbar isLoginPage={isLoginPage} style={{ position: 'relative', zIndex: '1000' }}>
      <Logo onClick={handleHome} src="/logo.png" alt="Map This Logo" />
      <NavbarContainer>
        {isAppPage && navData ? (
          <>
            {navData && navData.currentPlan === "Freemium" && (
                    <UpgradeButton 
                        style={{ background: 'linear-gradient(180deg, rgb(213, 198, 241) 0%, rgb(246, 174, 177) 100%)', color: '#002b31' }} 
                        onClick={togglePricingPopup}
                        disabled={isUpgradeLoading}
                    >
                        {isUpgradeLoading ? 'One momemt...' : 'Upgrade ✨'}
                    </UpgradeButton>
            )}
            {navData && (navData.currentPlan === "Freemium" || navData.currentPlan === "Paid") && (
              <CreditsContainer>
                {/* Check if userData and userData.userUsage are defined before accessing properties */}
                {userData && userData.userUsage && (
                  <CreditsText>{(userData.userUsage.MonthlyLimit - userData.userUsage.CurrentMonthUsage) * 10}</CreditsText>
                )}
                <FontAwesomeIcon icon={faCoins} />
                <Tooltip>Credits are refreshed every month</Tooltip>
              </CreditsContainer>
            )}
            <UserDropdown signOut={signOut} navData={navData} />
          </>
        ) : (
          <>
          {userData &&(
            <Button onClick={handleHome}>My Home</Button>
          )}

          {!userData && (
            <>
            <Button onClick={handleLogin}>Log In</Button>
            <Button onClick={handleTools} style={{backgroundColor:'white',color: '#002b31' }}>Discover All Tools</Button>
            </>
          )}

          </>
        )}
      </NavbarContainer>
      <Modal show={isPricingPopupOpen} onHide={togglePricingPopup} centered className='pricing-modal'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <PricingComponent />
        </Modal.Body>
      </Modal>
    </Navbar>

      {/* Flash Sale Alert Banner */}
      {(location.pathname !== '/login' && location.pathname !== '/pricing' && location.pathname !== '/congratulations' && location.pathname !== '/see-you-again' && location.pathname !== '/how-credits-work') && !timeLeft.hasSaleStarted &&(
      <div className="saleBanner">
        <div className="countdown">
          
            <>
                    <div className="saleMessage">We are having a flash sale! 🎉</div>
            <div>
            Ends in &nbsp; 
              <span className="timeBox">{timeLeft.days}</span> days
              <span className="timeBox">{timeLeft.hours} </span> hours
              <span className="timeBox">{timeLeft.minutes}</span> minutes
              <span className="timeBox">{timeLeft.seconds}</span> seconds
            </div>
            <div className='discountMessage'>
              <span>First 3 months <strong>ONLY $1/month</strong> - Limited time only!</span>
            </div>
            <div className='discountButton'>
              <Button onClick={pricingRedirect} style={{ background: 'linear-gradient(180deg, rgb(213, 198, 241) 0%, rgb(246, 174, 177) 100%)', color: '#002b31' }}>Don't Miss Out!</Button>
            </div>
            </>
          
        </div>
      </div>
    )}
</div>
  );
};

export default NavBar;