import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import './MindMapNode.css';

function MindMapNode({ id, data }) {
  const textareaRef = useRef(null);
  const updateNodeLabel = data.onLabelChange;

  // Adjust the height of the textarea based on content
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '16px'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust to scrollHeight
    }
  };

  useLayoutEffect(() => {
    adjustTextareaHeight(); // Adjust height when the component is mounted and data.label changes
  }, [data.label]);

  useEffect(() => {
    // Focus the textarea when the node is created
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus({ preventScroll: true });
      }
    }, 1);
  }, []);

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className="inputWrapper">
        <div className="dragHandle">
          <svg viewBox="0 0 24 24">
            <path
              fill="#333"
              stroke="#333"
              strokeWidth="1"
              d="M15 5h2V3h-2v2zM7 5h2V3H7v2zm8 8h2v-2h-2v2zm-8 0h2v-2H7v2zm8 8h2v-2h-2v2zm-8 0h2v-2H7v2z"
            />
          </svg>
        </div>
        <textarea
          value={data.label}
          onChange={(evt) => {
            updateNodeLabel(id, evt.target.value);
            adjustTextareaHeight(); // Adjust height as the user types
          }}
          className="inputNode"
          ref={textareaRef}
          style={{ resize: 'none', overflow: 'hidden', minHeight: '30px' }} // Ensure minHeight and auto height adjustment
        />
      </div>
      <Handle type="source" position={Position.Bottom} />
    </>
  );
}

export default MindMapNode;
