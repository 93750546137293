import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../ui-components/LandingPage/Navbar";
import UseCases from "../ui-components/UseCases";
import ToolCards from "../ui-components/ToolCards";
import WallOfLove from "../ui-components/LandingPage/WallOfLove";
import Footer from "../ui-components/LandingPage/Footer";
import './AllTools.css'; // Assuming you have a CSS file for ToolsPage

const AllTools = () => {
  return (
    <div>
      <NavBar />

      {/* Breadcrumb Element */}
      <div className="breadcrumb">
        <Link to="/" className="breadcrumb-link">
          Map This
        </Link>
        <span className="breadcrumb-separator"> &gt; </span>
        <span>Tools</span> {/* Current page doesn't need a link */}
      </div>
      <ToolCards />
      <UseCases />
      <WallOfLove />
      <Footer />
    </div>
  );
};

export default AllTools;
