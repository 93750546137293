import React, { useState, useRef, useEffect } from 'react';

const ChatPopup = ({ pdfName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const messagesEndRef = useRef(null);

  const toggleChat = () => setIsOpen(!isOpen);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      const newMessage = {
        id: Date.now(),
        text: inputValue,
        sender: 'user'
      };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInputValue('');
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch('https://4kunvjwvlvkepjzhaoqb2xhthi0awabh.lambda-url.us-east-1.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pdfName: pdfName,
            query: newMessage.text,
          }),
        });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let botMessageId = Date.now();
        setMessages(prevMessages => [...prevMessages, { id: botMessageId, text: '', sender: 'bot' }]);

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          setMessages(prevMessages => 
            prevMessages.map(msg => 
              msg.id === botMessageId ? { ...msg, text: msg.text + chunk } : msg
            )
          );
        }
      } catch (err) {
        console.error('Error:', err);
        setError('An error occurred while fetching the response. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      zIndex: 1000
    }}>
      {isOpen ? (
        <div style={{
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          width: '300px',
          height: '450px',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            borderBottom: '1px solid #ccc',
            position: 'relative'
          }}>
            <h2 style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
              Chat
              <span style={{
                marginLeft: '8px',
                backgroundColor: '#ffcc00',
                color: '#000',
                padding: '2px 6px',
                fontSize: '10px',
                borderRadius: '4px',
              }}>BETA</span>
                          <button 
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '14px',
                cursor: 'pointer',
                marginRight: '10px'
              }}>ℹ️</button>
            </h2>

            {showTooltip && (
              <div style={{
                position: 'absolute',
                top: '100%',
                right: '10px',
                backgroundColor: '#f1f1f1',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '8px',
                width: '200px',
                fontSize: '12px',
                color: '#333',
                boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                zIndex: 10
              }}>
                <p><strong>Information:</strong></p>
                <ul style={{ paddingLeft: '16px', margin: 0 }}>
                  <li>This feature is in BETA, as a paying customer you have early access</li>
                  <li>Currently only works with PDF uploads</li>
                  <li>Chat will be lost once you leave the page</li>
                </ul>
              </div>
            )}
            <button onClick={toggleChat} style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              fontSize: '18px'
            }}>×</button>
          </div>
          <div style={{
            flexGrow: 1,
            overflowY: 'auto',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: messages.length ? 'flex-start' : 'center',
            alignItems: 'center',
            color: '#666'
          }}>
            {messages.length === 0 && (
              <div style={{ textAlign: 'center' }}>
                <p>Welcome to the chat! Here’s what you can do:</p>
                <ul style={{ paddingLeft: '20px', textAlign: 'left' }}>
                  <li>Ask questions about sections</li>
                  <li>Retrieve specific details</li>
                </ul>
                <p>Type a message below to get started.</p>
              </div>
            )}
            {messages.map((message) => (
              <div
                key={message.id}
                style={{
                  marginBottom: '10px',
                  padding: '8px',
                  borderRadius: '8px',
                  maxWidth: '80%',
                  backgroundColor: message.sender === 'user' ? '#007bff' : '#f1f1f1',
                  color: message.sender === 'user' ? 'white' : 'black',
                  alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                  marginLeft: message.sender === 'user' ? 'auto' : '0'
                }}
              >
                {message.text}
              </div>
            ))}
            {isLoading && (
              <div style={{
                alignSelf: 'flex-start', // Align to the left
                display: 'flex',
                padding: '10px'
              }}>
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}
            {error && (
              <div style={{ color: 'red', textAlign: 'center', padding: '10px' }}>
                {error}
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div style={{
            textAlign: 'center',
            fontSize: '8px',
            color: '#666',
            padding: '10px',
            borderTop: '1px solid #eee'
          }}>
            Please note that the information provided might be inaccurate.
          </div>
          <form onSubmit={handleSubmit} style={{
            borderTop: '1px solid #ccc',
            padding: '10px',
            display: 'flex'
          }}>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Type a message..."
              style={{
                flexGrow: 1,
                marginRight: '10px',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '20px',
                fontSize: '14px',
                outline: 'none'
              }}
            />
            <button type="submit" style={{
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '20px',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 'bold',
              transition: 'background-color 0.2s',
            }}>Send</button>
          </form>
        </div>
      ) : (
        <button onClick={toggleChat} style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          fontSize: '24px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          💬
        </button>
      )}
      <style>
        {`
          .typing-indicator {
            display: flex;
            gap: 4px;
          }
          .typing-indicator span {
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: #bbb;
            border-radius: 50%;
            animation: typing 1.2s infinite ease-in-out;
          }
          .typing-indicator span:nth-child(1) { animation-delay: 0s; }
          .typing-indicator span:nth-child(2) { animation-delay: 0.2s; }
          .typing-indicator span:nth-child(3) { animation-delay: 0.4s; }

          @keyframes typing {
            0%, 80%, 100% { transform: scale(0); }
            40% { transform: scale(1); }
          }
        `}
      </style>
    </div>
  );
};

export default ChatPopup;
