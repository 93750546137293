import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Mermaid from '../util/mermaid';
import './MindmapViewer.css';
import Footer from '../ui-components/LandingPage/Footer';
import HamsterLoader from '../ui-components/HamsterLoader';
import WallOfLove from '../ui-components/LandingPage/WallOfLove';
import { FaArrowCircleRight } from 'react-icons/fa';
import MermaidGantt from '../util/mermaidGantt';
import mermaid from 'mermaid';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ReactFlowProvider } from 'react-flow-renderer';
import ReactFlowChart from '../ui-components/ReactFlow/ReactFlowComponents/ReactFlow';

const MindmapViewer = () => {
  const { mindmapId } = useParams();
  const [mindmapData, setMindmapData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isGantt, setIsGantt] = useState(false);  
  const [isMindmap, setIsMindmap] = useState(false);
  const [isFlowChart, setIsFlowChart] = useState(false); 
  const [isReactFlow, setIsReactFlow] = useState(false);

  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate('/login');
  };

  const redirectToHome = () => {
    navigate('/');
  }

  useEffect(() => {
    const fetchMindmapData = async () => {
      const url = window.location.hostname === 'localhost'
      ? 'https://o9ikkotra6.execute-api.us-east-1.amazonaws.com/test' // Replace with your local URL
      : 'https://o3i27k9zpl.execute-api.us-east-1.amazonaws.com/dev';

      setIsLoading(true);


      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mindmapId }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch mindmap data');
        }

        const data = await response.json();

        const mindmapData = data.body;
        setMindmapData(mindmapData);

        // Detect the type of mindmap data
          const firstWord = mindmapData.trim().split(/\s+/)[0]; // Split by spaces and get the first word
          // Manually detect the type based on the first word
          if (firstWord.toLowerCase() === 'flowchart') {
            setIsFlowChart(true);
          } else if (firstWord.toLowerCase() === 'gantt') {
            setIsGantt(true);
          } else if (firstWord.toLowerCase() === 'mindmap') {
            setIsMindmap(true);
          } else if (firstWord.startsWith('{')) {
            setIsReactFlow(true);
          } 

      } catch (error) {
        console.error("Error fetching mindmap data:", error);
      } finally {
        setIsLoading(false);
        //console.log("Loading complete");
      }
    };

    fetchMindmapData();
  }, [mindmapId]);

  if (isLoading) {
    //console.log("Loading mindmap data...");
    return (
      <div className='main-layout-mindmap-viewer'>
        <div className='loading-container' style={{backgroundImage: 'url("/backgroundPattern.png")', backgroundSize: 'contain', backgroundRepeat: 'repeat'}}>
          <div className="mindmap-header">
              <div className="mindmap-header-left">
                  <img onClick={redirectToHome} src="/logo-with-name-black.png" alt="Map This Logo without name" className="app-header-image" />
              </div>
              <p className='file-name-app'></p>
              <div className="mindmap-header-right">
                  <button className='make-own-mindmap' onClick={redirectToLogin}>
                  Create your 🫵🏻 Mindmap <FaArrowCircleRight />
                  </button>
              </div>
          </div>
          <div className='hamsterLoader'>
              <HamsterLoader />
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="main-layout-mindmap-viewer">
        <div className='content-container' style={{backgroundImage: 'url("/backgroundPattern.png")', backgroundSize: 'contain', backgroundRepeat: 'repeat'}}>
            <div className="mindmap-header">
                        <div className="mindmap-header-left">
                            <img onClick={redirectToHome} src="/logo-with-name-black.png" alt="Map This Logo with name" className="app-header-image" />
                        </div>
                        <p className='file-name-app'></p>
                        <div className="mindmap-header-right">
                            <button className='make-own-mindmap' onClick={redirectToLogin}>
                            Create your 🫵🏻 Mindmap <FaArrowCircleRight />
                            </button>
                        </div>
            </div>
            <div className='mindmap-container' >

                {isMindmap && (
                 <Mermaid key={mindmapData ? mindmapData.length : 0} chart={mindmapData} />
                )}

            {isFlowChart && (
                            <TransformWrapper 
                            defaultScale={0.5}
                            minScale={0.5}
                            maxScale={5}
                            centerOnInit={true}
                          >
                            
                            <TransformComponent>
                            <div className="flowchart-container">
                              <Mermaid key={mindmapData ? mindmapData.length : 0} chart={mindmapData} />
                            </div>
                            </TransformComponent>
                            </TransformWrapper>
                        )}

            {isGantt && (
                <div className="gantt-container">
                  <MermaidGantt key={mindmapData ? mindmapData.length : 0} chart={mindmapData} />
                </div>
            )}

              {isReactFlow && (
              <div className="react-flow-container">
                    <ReactFlowProvider>
                <ReactFlowChart initialJson={mindmapData}/>
  
                </ReactFlowProvider>        
                      </div>

            )}
            </div>
        </div>
        <WallOfLove />
        <Footer />
    </div>
  );
};

export default MindmapViewer;
