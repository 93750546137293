import React, { useCallback, useRef, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  addEdge,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import MindMapNode from './MindMapNode';
import useLayoutedElements from '../hooks/useLayoutedElements';
import useReactFlowFileStore from '../diagramStore'; // Zustand store
import DownloadButton from './DownloadButton';

const nodeTypes = {
  mindmap: MindMapNode, // Only MindMapNode
};

const ReactFlowChart = ({ initialJson, fileName }) => {
  const addOrUpdateFile = useReactFlowFileStore((state) => state.addOrUpdateFile);
  const getFile = useReactFlowFileStore((state) => state.getFile);
  const saveFile = useReactFlowFileStore((state) => state.saveFile);
  const connectingNodeId = useRef(null);
  const reactFlowInstance = useReactFlow();
  const [parsedJson, setParsedJson] = useState(null);
  const { getLayoutedElements } = useLayoutedElements();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const isMounted = useRef(false); // Flag to track initial mounting
  const saveTimerRef = useRef(null); // Ref to track the timer

  // Function to handle saveFile and reset the timer
  const startSaveTimer = useCallback(() => {
    if (saveTimerRef.current) {
      clearTimeout(saveTimerRef.current); // Reset timer on any new change
    }

    saveTimerRef.current = setTimeout(() => {
      if (isMounted.current) {
        //console.log('SaveFile triggered after 1 seconds:', new Date().toLocaleTimeString());
        saveFile(fileName);
      }
    }, 1000); // 1 seconds timer
  }, [fileName, saveFile]);

  // Set isMounted to true after component mounts
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Parse the initial JSON and load nodes and edges from the store
  useEffect(() => {
    try {
      const parsed = typeof initialJson === 'string' ? JSON.parse(initialJson) : initialJson;
      setParsedJson(parsed);
      if (parsed && fileName) {
        addOrUpdateFile(fileName, JSON.stringify(parsed));
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      setParsedJson(null);
    }
  }, [initialJson, fileName, addOrUpdateFile]);

  // Load nodes and edges from the file store without triggering save
  useEffect(() => {
    if (fileName) {
      const diagram = getFile(fileName);
      if (diagram) {
        const parsed = JSON.parse(diagram);
        setNodes(parsed.nodes || []);
        setEdges(parsed.edges || []);
      } else {
        console.warn('No diagram found in store.');
      }
    }
  }, [fileName, getFile]);

  // Remember which node started the connection
  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const getChildNodePosition = (event, parentNode) => {
    const panePosition = reactFlowInstance.project({
      x: event.clientX,
      y: event.clientY,
    });

    const isAbove = panePosition.y < parentNode.position.y + (parentNode.height || 0) / 2;

    return {
      x: panePosition.x,
      y: isAbove ? panePosition.y - 50 : panePosition.y + 50,
    };
  };

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane && connectingNodeId.current) {
        const parentNode = nodes.find((node) => node.id === connectingNodeId.current);
        const childNodePosition = getChildNodePosition(event, parentNode);

        if (parentNode) {
          const newNodeId = `node_${Date.now()}`;
          const newNode = {
            id: newNodeId,
            type: 'mindmap',
            position: childNodePosition,
            data: {
              label: '', // Empty label for new nodes
              ...Object.fromEntries(
                Object.entries(parentNode.data).filter(([key]) => key !== 'label')
              ),
            },
            style: {
              ...parentNode.style,
            },
          };

          const isAbove = childNodePosition.y < parentNode.position.y;
          const newEdge = {
            id: `edge_${Date.now()}`,
            source: isAbove ? newNodeId : connectingNodeId.current,
            target: isAbove ? connectingNodeId.current : newNodeId,
          };

          setNodes((nds) => [...nds, newNode]);
          setEdges((eds) => [...eds, newEdge]);

          addOrUpdateFile(
            fileName,
            JSON.stringify({ nodes: [...nodes, newNode], edges: [...edges, newEdge] })
          );
          startSaveTimer(); // Start or reset the timer
        }
      }
    },
    [
      nodes,
      edges,
      setNodes,
      setEdges,
      addOrUpdateFile,
      fileName,
      reactFlowInstance,
      startSaveTimer,
    ]
  );

  // Update node label when editing
  const handleNodeLabelChange = useCallback(
    (id, newLabel) => {
      setNodes((nds) => {
        const updatedNodes = nds.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, label: newLabel } } : node
        );
        addOrUpdateFile(fileName, JSON.stringify({ nodes: updatedNodes, edges }));
        startSaveTimer(); // Start or reset the timer
        return updatedNodes;
      });
    },
    [addOrUpdateFile, fileName, edges, startSaveTimer]
  );

  // Handle edge connection
  const onConnect = useCallback(
    (params) => {
      const newEdges = addEdge(params, edges);
      setEdges(newEdges);
      addOrUpdateFile(fileName, JSON.stringify({ nodes, edges: newEdges }));
      startSaveTimer(); // Start or reset the timer
    },
    [edges, nodes, addOrUpdateFile, fileName, setEdges, startSaveTimer]
  );

  // Handle node and edge changes and save them
  const handleNodesChange = useCallback(
    (changes) => {
      onNodesChange(changes);
      addOrUpdateFile(fileName, JSON.stringify({ nodes, edges }));
      startSaveTimer(); // Start or reset the timer
    },
    [onNodesChange, addOrUpdateFile, fileName, nodes, edges, startSaveTimer]
  );

  const handleEdgesChange = useCallback(
    (changes) => {
      onEdgesChange(changes);
      addOrUpdateFile(fileName, JSON.stringify({ nodes, edges }));
      startSaveTimer(); // Start or reset the timer
    },
    [onEdgesChange, addOrUpdateFile, fileName, nodes, edges, startSaveTimer]
  );

  const proOptions = { hideAttribution: true };
  return parsedJson ? (
    <div style={{ width: '100%', height: '95vh' }}>
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          type: 'mindmap',
          data: {
            ...node.data,
            onLabelChange: handleNodeLabelChange,
          },
          style: {
            ...node.style,
            width: 'auto', // Allow node width to adjust to content
          },
        }))}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        fitView
      >
        <Background />
        <Controls
          style={{ display: 'flex', flexDirection: 'column-reverse', alignItems: 'center' }}
        >
          {/* Layout buttons */}
          <button
            onClick={() => getLayoutedElements({ 'elk.algorithm': 'layered', 'elk.direction': 'DOWN' })}
            style={{ backgroundColor: 'white', border: 'none', padding: '5px', cursor: 'pointer' }}
          >
            <img
              src="/icons8-vertical-layout-96.png"
              alt="Vertical Layout"
              style={{ width: '16px', height: '16px' }}
            />
          </button>
          <button
            onClick={() => getLayoutedElements({ 'elk.algorithm': 'layered', 'elk.direction': 'RIGHT' })}
            style={{ backgroundColor: 'white', border: 'none', padding: '5px', cursor: 'pointer' }}
          >
            <img
              src="/icons8-grip-horizontal-96.png"
              alt="Horizontal Layout"
              style={{ width: '16px', height: '16px' }}
            />
          </button>

          {/* Download Button */}
          <DownloadButton />
        </Controls>
      </ReactFlow>
    </div>
  ) : (
    <div>Error: Invalid JSON input</div>
  );
};

export default ReactFlowChart;
