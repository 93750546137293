import React, { useEffect } from "react";
import mermaid from "mermaid";

const MermaidGantt = ({ chart }) => {
  useEffect(() => {
    // Initialize Mermaid based on the detected chart type
    mermaid.initialize({
      startOnLoad: true,
      theme: "default",
      gantt: {
        titleTopMargin: 50, // For Gantt charts
        barHeight: 45,
        barGap: 15,
        topPadding: 75,
        leftPadding: 200,
        rightPadding: 100,
        fontSize: 16,
        axisLabelFontSize: 14,
      },
      securityLevel: "loose", // Allow external plugins
    });
    // Parse and render the chart
    try {
      mermaid.contentLoaded();
    } catch (error) {
      console.error("Mermaid rendering error:", error);
    }
  }, [chart]);

  return (

      <pre id="mermaid-chart" className="mermaid gantt">
        {chart}
      </pre>

  );
};

export default MermaidGantt;