import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import '@aws-amplify/ui-react/styles.css';
import Landing from './Pages/Landing';
import PDFToMindMap from './Pages/pdftomindmap';
import PrivateRoute from './auth/privateroute';
import LoginPage from './Pages/LoginPage';
import Home from './Pages/Home';
import studioTheme from './ui-components/studioTheme';
import NavBar from './ui-components/LandingPage/Navbar';
import { ThemeProvider } from '@aws-amplify/ui-react';
import SupportPage from './Pages/Support';
import MyAccount from './Pages/MyAccount';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import MindmapViewer from './Pages/MindmapViewer';
import FAQ from './Pages/FAQ';
import OnboardingPage from './Pages/Onboarding';
import Pricing from './Pages/Pricing';
import Congratulations from './Pages/Congratulations';
import MaybeNextTime from './Pages/MaybeNextTime';
import HowCreditsWork from './Pages/HowCreditsWork';
import RefundPolicyPage from './Pages/RefundPolicy';
import ToolPage from "./Pages/ToolPage";
import AllTools from './Pages/AllTools';
import MindMapGenerator from './ui-components/ReactFlow/ReactFlowComponents/ReactFlow';
import AboutTeam from './Pages/AboutUs';
Amplify.configure(config);
function NotFound() {
  return <Navigate to="/" />;
}
const ConditionalNavBar = () => {
  const location = useLocation();
  if (location.pathname === '/login' || location.pathname === '/signup') {
    const isLoginPage = location.pathname === '/login';
    return <NavBar isLoginPage={isLoginPage} width="100%"/>;
  }
  return null;
};

const initialJson = {
  "nodes": [
    {
      "id": "1",
      "type": "mindmap",
      "data": {
        "label": "Venture for Canada Internship Program"
      },
      "position": {
        "x": 0,
        "y": 0
      },
      "style": {
        "background": "#F5F5F5",
        "border": "2px solid #2196F3"
      }
    },
    {
      "id": "2",
      "type": "mindmap",
      "data": {
        "label": "Latest Updates & Changes"
      },
      "position": {
        "x": 100,
        "y": 100
      },
      "style": {
        "background": "#E3F2FD"
      }
    },
    {
      "id": "3",
      "type": "mindmap",
      "data": {
        "label": "Overview & Eligibility"
      },
      "position": {
        "x": -100,
        "y": 100
      },
      "style": {
        "background": "#E3F2FD"
      }
    },
    {
      "id": "4",
      "type": "mindmap",
      "data": {
        "label": "Financials & Dates"
      },
      "position": {
        "x": 100,
        "y": 200
      },
      "style": {
        "background": "#E3F2FD"
      }
    },
    {
      "id": "5",
      "type": "mindmap",
      "data": {
        "label": "How to Apply"
      },
      "position": {
        "x": -100,
        "y": 200
      },
      "style": {
        "background": "#E3F2FD"
      }
    },
    {
      "id": "6",
      "type": "mindmap",
      "data": {
        "label": "Hiring an Intern"
      },
      "position": {
        "x": 100,
        "y": 300
      },
      "style": {
        "background": "#E3F2FD"
      }
    },
    {
      "id": "7",
      "type": "mindmap",
      "data": {
        "label": "Intern Training"
      },
      "position": {
        "x": -100,
        "y": 300
      },
      "style": {
        "background": "#E3F2FD"
      }
    },
    {
      "id": "8",
      "type": "mindmap",
      "data": {
        "label": "Promoting & Sharing"
      },
      "position": {
        "x": 0,
        "y": 400
      },
      "style": {
        "background": "#E3F2FD"
      }
    },
    {
      "id": "9",
      "type": "mindmap",
      "data": {
        "label": "FALL 2024 Hiring Period Closed"
      },
      "position": {
        "x": 200,
        "y": 200
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "10",
      "type": "mindmap",
      "data": {
        "label": "Employer Eligibility"
      },
      "position": {
        "x": 300,
        "y": 200
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "11",
      "type": "mindmap",
      "data": {
        "label": "New Minimum Wage"
      },
      "position": {
        "x": 400,
        "y": 200
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "12",
      "type": "mindmap",
      "data": {
        "label": "Job Board Update"
      },
      "position": {
        "x": 500,
        "y": 200
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "13",
      "type": "mindmap",
      "data": {
        "label": "Employer Criteria",
        "description": "Entrepreneurs, start-ups, small businesses, non-profits, social enterprises, or band councils"
      },
      "position": {
        "x": -200,
        "y": 200
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "14",
      "type": "mindmap",
      "data": {
        "label": "Student Criteria",
        "description": "Enrolled in Canadian college/university, studying any discipline"
      },
      "position": {
        "x": -300,
        "y": 200
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "15",
      "type": "mindmap",
      "data": {
        "label": "Funding Details"
      },
      "position": {
        "x": 200,
        "y": 300
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "16",
      "type": "mindmap",
      "data": {
        "label": "Internship Dates"
      },
      "position": {
        "x": 300,
        "y": 300
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "17",
      "type": "mindmap",
      "data": {
        "label": "How to Apply Steps"
      },
      "position": {
        "x": -200,
        "y": 300
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "18",
      "type": "mindmap",
      "data": {
        "label": "Identifying Students"
      },
      "position": {
        "x": -300,
        "y": 300
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "19",
      "type": "mindmap",
      "data": {
        "label": "Documentation & Approval"
      },
      "position": {
        "x": -400,
        "y": 300
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "20",
      "type": "mindmap",
      "data": {
        "label": "Training Program"
      },
      "position": {
        "x": -200,
        "y": 400
      },
      "style": {
        "background": "#E1F5FE"
      }
    },
    {
      "id": "21",
      "type": "mindmap",
      "data": {
        "label": "Professional Development"
      },
      "position": {
        "x": -300,
        "y": 400
      },
      "style": {
        "background": "#E1F5FE"
      }
    }
  ],
  "edges": [
    {
      "id": "e1-2",
      "source": "1",
      "target": "2",
      "type": "smoothstep",
      "animated": true
    },
    {
      "id": "e1-3",
      "source": "1",
      "target": "3",
      "type": "smoothstep",
      "animated": true
    },
    {
      "id": "e1-4",
      "source": "1",
      "target": "4",
      "type": "smoothstep",
      "animated": true
    },
    {
      "id": "e1-5",
      "source": "1",
      "target": "5",
      "type": "smoothstep",
      "animated": true
    },
    {
      "id": "e1-6",
      "source": "1",
      "target": "6",
      "type": "smoothstep",
      "animated": true
    },
    {
      "id": "e1-7",
      "source": "1",
      "target": "7",
      "type": "smoothstep",
      "animated": true
    },
    {
      "id": "e1-8",
      "source": "1",
      "target": "8",
      "type": "smoothstep",
      "animated": true
    },
    {
      "id": "e2-9",
      "source": "2",
      "target": "9",
      "type": "default"
    },
    {
      "id": "e2-10",
      "source": "2",
      "target": "10",
      "type": "default"
    },
    {
      "id": "e2-11",
      "source": "2",
      "target": "11",
      "type": "default"
    },
    {
      "id": "e2-12",
      "source": "2",
      "target": "12",
      "type": "default"
    },
    {
      "id": "e3-13",
      "source": "3",
      "target": "13",
      "type": "default"
    },
    {
      "id": "e3-14",
      "source": "3",
      "target": "14",
      "type": "default"
    },
    {
      "id": "e4-15",
      "source": "4",
      "target": "15",
      "type": "straight"
    },
    {
      "id": "e4-16",
      "source": "4",
      "target": "16",
      "type": "straight"
    },
    {
      "id": "e5-17",
      "source": "5",
      "target": "17",
      "type": "straight"
    },
    {
      "id": "e5-18",
      "source": "5",
      "target": "18",
      "type": "straight"
    },
    {
      "id": "e5-19",
      "source": "5",
      "target": "19",
      "type": "straight"
    },
    {
      "id": "e7-20",
      "source": "7",
      "target": "20",
      "type": "default"
    },
    {
      "id": "e7-21",
      "source": "7",
      "target": "21",
      "type": "default"
    }
  ]
};

const ValidateMindmapId = ({ children }) => {
  const { mindmapId } = useParams();
  // Regular expression to validate UUID (version 4)
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const isValid = uuidRegex.test(mindmapId);
  if (!isValid) {
    return <Navigate to="/" />;
  }
  return children;
};
function App() {
  return (
    <ThemeProvider theme={studioTheme}>
      <Router>
        <ConditionalNavBar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/onboarding" element={<OnboardingPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/congratulations" element={<Congratulations />} />
          <Route path="/see-you-again" element={<MaybeNextTime />} />
          <Route path="/how-credits-work" element={<HowCreditsWork />} />
          <Route path="/refund-policy" element={<RefundPolicyPage />} />
          <Route path="/about-us" element={<AboutTeam/>} />
          <Route path="/tools" element={<AllTools />} />

          <Route path="/tools/pdf-to-mindmap" element={<ToolPage type="pdf-to-mindmap" />} />
          <Route path="/tools/notes-to-mindmap" element={<ToolPage type="notes-to-mindmap" />} />
          <Route path="/tools/ai-magic-to-mindmap" element={<ToolPage type="ai-magic-to-mindmap" />} />

        
          <Route path="/tools/pdf-to-gantt" element={<ToolPage type="pdf-to-gantt" />} />
          <Route path="/tools/notes-to-gantt" element={<ToolPage type="notes-to-gantt" />} />


          <Route path="/tools/pdf-to-flowchart" element={<ToolPage type="pdf-to-flowchart" />} />
          <Route path="/tools/notes-to-flowchart" element={<ToolPage type="notes-to-flowchart" />} />
          <Route path="/tools/ai-magic-to-flowchart" element={<ToolPage type="ai-magic-to-flowchart" />} />

          <Route
            path="/app"
            element={
              <PrivateRoute>
                <PDFToMindMap />
              </PrivateRoute>
            }
          />
          <Route
            path="/:mindmapId"
            element={
              <ValidateMindmapId>
                  <MindmapViewer />
              </ValidateMindmapId>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
