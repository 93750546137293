// hooks/useLayoutedElements.js
import { useCallback } from 'react';
import ELK from 'elkjs/lib/elk.bundled.js';
import { useReactFlow } from 'reactflow';

const elk = new ELK();

const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
  const defaultOptions = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': 100,
    'elk.spacing.nodeNode': 80,
  };

  const getLayoutedElements = useCallback((options) => {
    const layoutOptions = { ...defaultOptions, ...options };
    const graph = {
      id: 'root',
      layoutOptions: layoutOptions,
      children: getNodes().map((node) => ({
        ...node,
        width: node.measured?.width || 150,
        height: node.measured?.height || 50,
      })),
      edges: getEdges(),
    };

    elk.layout(graph).then(({ children }) => {
      children.forEach((node) => {
        node.position = { x: node.x, y: node.y };
      });

      setNodes(children);
      window.requestAnimationFrame(() => fitView());
    });
  }, [getNodes, setNodes, getEdges, fitView]);

  return { getLayoutedElements };
};

export default useLayoutedElements;
