import React, { useState, useRef, useEffect } from 'react';
import './uploadmodal.css'; 
import UploadIcon from "../assets/icons8-upload-to-cloud-64.png";
import TextInputIcon from '../assets/icons8-paste-as-text-60.png';
import AIMagicIcon from '../assets/icons8-magic-wand-64.png';
import MapThisIcon from '../assets/Vector.png'; 
import FileUpload from '../util/FileUpload';
import getUserData from '../userManagement/getUserData';
import updateUserUsage from '../userManagement/updateUsage';
import { calculateCredits } from '../util/calculateCredits';
import GenerationConfirmation from './GenerationConfirmation';

const UploadModal = ({ closeModal, onMapText, onMapAI, handleFileUploadSuccess, handleCreditsNeededChange, isFirstMindMap, userID}) => {

  const [textInput, setTextInput] = useState('');
  const [aiInput, setAIInput] = useState('');
  const [showCreditsConfirmation, setShowCreditsConfirmation] = useState(false);
  const [creditsNeeded, setCreditsNeeded] = useState(0);
  const [isPaidMember, setIsPaidMember] = useState(false);
  const [resetDate, setResetDate] = useState('');
  const [userCredits, setUserCredits] = useState(null);
  const [inputError, setInputError] = useState(false);
  const fileInputRef = useRef(null);
  const [modalState, setModalState] = useState(() => (isPaidMember ? 'upload' : 'text'));
  const [mapType, setMapType] = useState('flowchart');
  const [currentMonthUsage, setCurrentMonthUsage] = useState(0);
  const [MonthlyLimit, setMonthlyLimit] = useState(10);



  useEffect(() => {
    setInputError(false);
  }, [modalState]);
  
  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await getUserData();
      if (userData && userData.userUsage) {
        setCurrentMonthUsage(userData.userUsage.CurrentMonthUsage);
        setMonthlyLimit(userData.userUsage.MonthlyLimit);
        setIsPaidMember(userData.MembershipPlan === 'Paid' || userData.MembershipPlan === 'Pro Plan' || userData.MembershipPlan === 'Starter Plan' || userData.MembershipPlan === 'Annual Pro Plan' || userData.MembershipPlan === 'Pay Per Use');
        setResetDate(userData.userUsage.ResetDate);
        const availableCredits = (userData.userUsage.MonthlyLimit - userData.userUsage.CurrentMonthUsage);
        setUserCredits(availableCredits);
      }
    };
    fetchUserData();
  }, []);

  // Close modal when overlay is clicked
  const handleOverlayClick = () => closeModal();

  // Function to handle changing the tab based on dropdown selection
  const handleDropdownChange = (e) => {
    setShowCreditsConfirmation(false);
    setModalState(e.target.value);
  };


  const callApiGateway = async (text) => {
    try {
      let url = 'https://nrwqrsowv3.execute-api.us-east-1.amazonaws.com/dev'; // production URL
      if (window.location.hostname === 'localhost') {
        url = 'https://vhf1skrzek.execute-api.us-east-1.amazonaws.com/dev'; // testing URL
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: text })
      });
      const data = await response.json();
      return data;
    } catch (error) {
      //console.error('Error calling API Gateway:', error);
      return null;
    }
  };

  const handleMapText = async () => {
    if (!textInput.trim()) {
      setInputError(true);
      setTimeout(() => setInputError(false), 3000); 
      return;
    }
    const apiResponse = await callApiGateway(textInput);  //calculating tokens needed through API gateway
    if (apiResponse && apiResponse.body) {
      const responseBody = JSON.parse(apiResponse.body);
      if (responseBody.numTokens !== undefined) {
        const neededCredits = calculateCredits(responseBody.numTokens);
        setCreditsNeeded(neededCredits);
        setShowCreditsConfirmation(true);
      }
    }
  };

  const handleMapAI = async () => {
    if (!aiInput.trim()) {
      setInputError(true);
      setTimeout(() => setInputError(false), 3000); 
      return;
    }
    setCreditsNeeded(0.5); // Fixed credit for AI Magic
    setShowCreditsConfirmation(true);
  };


  const handleContinue = async (input, mapFunction) => {
    setShowCreditsConfirmation(false);
    await updateUserUsage(creditsNeeded);
    mapFunction(input, creditsNeeded, mapType);
  };
   
  const handleCancel = () => {
    setShowCreditsConfirmation(false);
  }

  const handleMapTypeChange = (e) => {
    setMapType(e.target.value);
  };


  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-container" onClick={(e) => e.stopPropagation()}>
            {/* Flex column for the close button and map type selector */}
            <div className="header-actions">
                <button className="close-button" onClick={closeModal}>×</button>
                <div className="map-type-selector">
                    <select value={mapType} onChange={handleMapTypeChange}>
                        <option value="reactflow_mindmap">Interactive Map</option>
                        <option value="flowchart">Flowchart</option>
                        <option value="mindmap">Mind Map</option>
                        <option value="gantt">Gantt Chart</option>

                    </select>
                </div>
                {mapType === "flowchart" && (
                  <div className="flowchart-info">
                    <p><img src="/icons8-flowchart-96.png" alt="Flowchart" style={{ width: "20px", height: "20px", marginRight: "5px" }} />NEW! Create a flowchart from PDF, notes, or AI-generated content</p>
                  </div>
                )}
                {mapType === "mindmap" && (
                  <div className="flowchart-info">
                    <p><img src="/icons8-brain-96.png" alt="Mind Map" style={{ width: "20px", height: "20px", marginRight: "5px" }} />Create a mindmap from PDF, notes, or AI-generated content</p>
                  </div>

                )}
              {mapType === "gantt" && (
                  <div className="flowchart-info">
                    <p><img src="/icons8-gantt-chart-96.png" alt="Gantt Chart" style={{ width: "20px", height: "20px", marginRight: "5px" }} />NEW! Create a gantt from PDF, notes, or any document</p>
                  </div>

                )}

              {mapType === "reactflow_mindmap" && (
                  <div className="react-flow-info">
                    <p><img src="/icons8-diagram-100.png" alt="Interactive Map" style={{ width: "20px", height: "20px", marginRight: "5px" }} />NEW! Create an interactive mindmap</p>
                  </div>

                )}


            </div>

            <div className="modal-header">
                {/* Dropdown for mobile screens */}
                <select 
                    className="modal-dropdown" 
                    onChange={handleDropdownChange} 
                    value={modalState}
                    aria-label="Select tab"
                >
                    <option value="upload">Upload</option>
                    <option value="text">From Text</option>
                    <option value="ai">AI Magic</option>
                </select>

                {/* Flex column for modal tabs */}
                <div className="modal-tabs">
                    <button 
                        className={`header-tab ${modalState === 'upload' ? 'active' : ''}`}
                        onClick={() => {
                            setShowCreditsConfirmation(false);
                            setModalState('upload');
                        }}
                    >
                        <img src={UploadIcon} alt="Upload" className="tab-icon" /> Upload
                        {!isPaidMember && <img src='/lock.png' style={{width:'24px'}} alt="Locked" className="lock-icon" />}
                    </button>
                    <button 
                        className={`header-tab ${modalState === 'text' ? 'active' : ''}`}
                        onClick={() => {
                            setShowCreditsConfirmation(false);
                            setModalState('text');
                        }}
                    >
                        <img src={TextInputIcon} alt="Text Input" className="tab-icon" /> From Notes
                    </button>
                    {mapType !== 'gantt' && ( 
                    <button 
                        className={`header-tab ${modalState === 'ai' ? 'active' : ''}`}
                        onClick={() => {
                            setModalState('ai');
                            setShowCreditsConfirmation(false);
                        }}
                    >
                        <img src={AIMagicIcon} alt="AI Magic" className="tab-icon" /> AI Magic
                    </button>
                    )}
                </div>
            </div>
            
            <div className={`modal-body ${modalState}`}>
                {modalState === 'upload' && (
                    <FileUpload 
                        onUploadSuccess={handleFileUploadSuccess}
                        onCreditsNeededChange={handleCreditsNeededChange}
                        isFirstMindMap={isFirstMindMap}
                        creditsNeeded={creditsNeeded}
                        userID={userID}
                        chartType={mapType}
                    />
                )}
                {modalState === 'text' && !showCreditsConfirmation && (
                    <div className="text-content">
                        <textarea
                            className={`textarea-upload-modal ${inputError && modalState === 'text' ? 'text-input-error' : ''}`}
                            placeholder="Type or paste the text you want to turn into a mind map"
                            value={textInput}
                            onChange={(e) => setTextInput(e.target.value)}
                        ></textarea>
                    </div>
                )}
                {modalState === 'ai' && !showCreditsConfirmation && (
                    <div className="ai-content">
                        <textarea
                            className={`textarea-upload-modal  ${inputError && modalState === 'ai' ? 'text-input-error' : ''}`}
                            type="text"
                            placeholder="Enter a topic for AI Magic"
                            value={aiInput}
                            onChange={(e) => setAIInput(e.target.value)}
                        ></textarea>
                    </div>
                )}
                {showCreditsConfirmation && (
                  <div className='generation-confirmation-ai-text'>
                    <GenerationConfirmation
                        creditsNeeded={creditsNeeded}
                        currentCredits={userCredits}
                        isPaidMember={isPaidMember}
                        onClose={handleCancel}
                        resetDate={resetDate}
                        onConfirm={() => handleContinue(
                            modalState === 'text' ? textInput : aiInput, 
                            modalState === 'text' ? onMapText : onMapAI
                        )}
                    />
                    </div>
                )}
            </div>
            {modalState !== 'upload' && !showCreditsConfirmation && (
                <button className="map-button" onClick={() => modalState === 'text' ? handleMapText() : handleMapAI()}>
                    <img src={MapThisIcon} alt="Map" className="button-icon" /> Map this
                </button>
            )}
        </div>
    </div>
);
};  

export default UploadModal;