import React, {useState} from 'react';
import './CTA.css'; // make sure to create this CSS file
import styled from 'styled-components';


const Button = styled.button`
  background: linear-gradient(to bottom, rgba(11, 121, 175, 0.31), rgba(11, 121, 175, 0));    
  border: 1px solid rgba(11, 121, 175, 0.8); // Border color changed to white
  border-radius: 42px; // Border-radius matched with your styled component
  padding: 10px 15px; // Padding matched with your styled component
  font-family: "Satoshi Variable-Bold", Helvetica;
  font-size: 16px;
  font-weight:600;
  color: black; // Text color is white
  cursor: pointer;
  transition: background-color 0.3s, color 0s; // Smooth transition for hover effect

  @media (max-width: 768px) {
    padding: 20px 40px; // Smaller padding for mobile
    font-size: 20px; // Smaller font size for mobile
  }
  &:hover {
    background: linear-gradient(to bottom, rgba(11, 121, 175, 0.31), rgba(11, 121, 175, 1));   
    color: white; // Dark text color on hover
    border-color: rgba(11, 121, 175, 0.8); // Lighter border color on hover
  }
`;

function CTA() {
  return (
    <div className="cta-container">
      <img src="study.png" alt="Descriptive text about the image" className="cta-image" />
      <div className="cta-text">
        <h1 className="cta-heading">Join over <span className='highlight-mindmap'>160,000+</span> users worldwide creating mind maps</h1>
        <p className="cta-subheading">Start for free — no credit card required.</p>
        <Button className="cta-button">Start Today</Button>
      </div>
    </div>
  );
}

export default CTA;
